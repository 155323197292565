import React, { Component,Fragment } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class Header extends Component {

  render() {

      const settings = {
      dots: false,
      infinite: true,
      autoplay:true,
      autoplaySpeed:3000,
      speed: 3000,
      slidesToShow: 1,
      slidesToScroll: 1
  };

    return (
      <Fragment>
        <div className="header" id="header">
            <div className="content-inner">
                <p>I'm</p>
                <h1>Subrata Dey Sarker</h1>
   
                <div className="sliderText">
                  <Slider {...settings}>
                      <div>
                        <h3>Web Developer</h3>
                      </div>
                      <div>
                        <h3>Web Designer</h3>
                      </div>
                      <div>
                        <h3>Front End Developer</h3>
                      </div>
                  </Slider>
                </div>
            </div>
        </div>
      </Fragment>
    )
  }
}

export default Header