import React, { Component,Fragment } from 'react';
import ApiUrl from "../../RestApi/AppUrl";
import ReactHtmlParser from "react-html-parser";
import RestClient from "../../RestApi/RestClient";
import axios from 'axios';
import Loading from "../Loading/Loading";
import WentWrong from "../WentWrong/WentWrong";

class Service extends Component {
    constructor() {
        super();
        this.state={
            myData:[],
            loading:true,
            error:false
        }
    }

    componentDidMount() {
        RestClient.GetRequest(ApiUrl.Services).then(result=>{
            if(result==null){
                this.setState({ error:true,loading:false})
            }
            else {
                this.setState({myData:result,loading:false})
            }

        }).catch(error=>{
            this.setState({ error:true,loading:false})
        })
    }

  render() {
    if(this.state.loading==true && this.state.error==false ){
        return  <Loading/>
    }
    else if(this.state.loading==false  && this.state.error==false)  {
    const  myList=this.state.myData;
    const myView=myList.map(myList=>{
        return  <div className="col-md-6">
                    <div className="srv-col">
                        <i className="{myList.icon}"></i>
                        <h3>{myList.service_name}</h3>
                        <p>{ReactHtmlParser(myList.service_des)}</p>
                    </div>
                </div>
        })

    return (
      <Fragment>
        <div className="service" id="service">
            <div className="content-inner">
                <div className="content-header">
                    <h2>Service</h2>
                </div>
                <div className="row align-items-center">
{myView}

                </div>
            </div>
        </div>
      </Fragment>
        );
    }
        else if(this.state.error==true){
            return  <WentWrong/>
        }

    }
}

export default Service