import React, { Component,Fragment } from 'react';


class LargeButton extends Component {

  render() {
    return (
      <Fragment>
          <div className="large-btn">
              <div className="content-inner">
                  <a className="btn" href="#"><i className="fa fa-download"></i>Resume</a>
                  <a className="btn" href="#"><i className="fa fa-hands-helping"></i>Hire Me</a>
              </div>
          </div>
      </Fragment>
    )
  }
}

export default LargeButton