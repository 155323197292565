class AppUrl {
    static BaseUrl='https://resumeapi.webhunter24.com/';
    static Education=this.BaseUrl+'/Education';
    static Experiences=this.BaseUrl+'/Experiences';
    static Services=this.BaseUrl+'/Services';
    static Projects=this.BaseUrl+'/Projects';
    static SettingData=this.BaseUrl+'/SettingData';
    static PersonalDetails=this.BaseUrl+'/PersonalDetails';
    static ContactSend=this.BaseUrl+'/ContactSend';
}

export default AppUrl

