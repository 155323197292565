import React, { Component,Fragment } from 'react';


class Footer extends Component {

  render() {
    return (
      <Fragment>
          <div className="footer">
              <div className="content-inner">
                  <div className="row align-items-center">
                      <div className="col-md-6">
                          <p>&copy; Copyright <a href="https://webhunter24.com">Subrata</a>. All Rights Reserved</p>
                      </div>
                      <div className="col-md-6">
                          <p>Powered by <a href="https://webhunter24.com">Subrata</a></p>
                      </div>
                  </div>
              </div>
          </div>
      </Fragment>
    )
  }
}

export default Footer