import React, {Component,Fragment} from 'react';
import Sidebar from "../components/Sidebar/Sidebar";
import Header from "../components/Header/Header";
import LargeButton from "../components/LargeButton/LargeButton";
import About from "../components/About/About";
import Education from "../components/Education/Education";
import Experience from "../components/Experience/Experience";
import Service from "../components/Service/Service";
import Portfolio from "../components/Portfolio/Portfolio";
import PersonalDetails from "../components/PersonalDetails/PersonalDetails";
import Contact from "../components/Contact/Contact";
import Footer from "../components/Footer/Footer";

class HomePage extends Component {
    render() {
        return (
            <Fragment>
                <div class="wrapper">
                    <Sidebar/>
                    <div class="content">
        				<Header/>
                        <LargeButton/>
                        <About/>
                        <Portfolio/>
                        <Service/>
                        <Experience/>
                        <Education/>
                        <PersonalDetails/>
                        <Contact/>
        				<Footer/>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default HomePage;