import React, { Component,Fragment } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar'
import ReactHtmlParser from "react-html-parser";
import Loading from "../Loading/Loading";
import WentWrong from "../WentWrong/WentWrong";
import {Link} from "react-router-dom";
import RestClient from "../../RestApi/RestClient";
import ApiUrl from "../../RestApi/AppUrl";
import axios from 'axios';

class About extends Component {
      constructor(props) {
        super();
        this.state={
            photo:" ",
            about:" ",
            loading:true,
            error:false
        }
    }

    componentDidMount() {
      window.scroll(0,0)

      RestClient.GetRequest(ApiUrl.SettingData).then(result=>{
          if(result==null){
              this.setState({ error:true,loading:false})
          }
          else{
              this.setState({
                  photo:result[0]['profile_photo'],
                  about:result[0]['about'],
                  loading:false
              })
          }


        }).catch(error=>{
            this.setState({ error:true,loading:false})
        })
    }
  render() {
    return (
      <Fragment>
        <div className="about" id="about">
            <div className="content-inner">
                <div className="content-header">
                    <h2>About Me</h2>
                </div>
                <div className="row align-items-center">
                    <div className="col-md-6 col-lg-5">
                        <img src={ this.state.photo } alt="Image" />
                    </div>
                    <div className="col-md-6 col-lg-7">
                        <p>{ ReactHtmlParser(this.state.about) }</p>
                        <a className="btn" href="#">Hire Me</a>
                    </div>
                </div>
                <div className="row">

                    <div className="col-md-6">
                        <div className="skills">
                            <div className="skill-name">
                                <p>PHP(Laravel)</p><p>80%</p>
                            </div>
                            <div>
                                <ProgressBar now={80} />
                            </div>
                            <div className="skill-name">
                                <p>Vue.js</p><p>60%</p>
                            </div>
                            <div>
                                <ProgressBar now={60} />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="skills">
                            <div className="skill-name">
                                <p>MySQL</p><p>80%</p>
                            </div>
                            <div>
                                <ProgressBar now={90} />
                            </div>
                            <div className="skill-name">
                                <p>Bootstrap</p><p>90%</p>
                            </div>
                            <div>
                                <ProgressBar now={90} />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="skills">
                            <div className="skill-name">
                                <p>HTML</p><p>90%</p>
                            </div>
                            <div>
                                <ProgressBar now={90} />
                            </div>
                            <div className="skill-name">
                                <p>CSS</p><p>90%</p>
                            </div>
                            <div>
                                <ProgressBar now={90} />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="skills">
                            <div className="skill-name">
                                <p></p>Jquery<p>70%</p>
                            </div>
                            <div>
                                <ProgressBar now={70} />
                            </div>
                            <div className="skill-name">
                                <p>Java Script</p><p>70%</p>
                            </div>
                            <div>
                                <ProgressBar now={70} />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
      </Fragment>
    )
  }
}

export default About