import React, { Component,Fragment } from 'react';
import ReactHtmlParser from "react-html-parser";
import validation from "../../validation/validation";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import Loading from "../Loading/Loading";
import WentWrong from "../WentWrong/WentWrong";
import RestClient from "../../RestApi/RestClient";
import ApiUrl from "../../RestApi/AppUrl";
import axios from 'axios';
class Contact extends Component {
      constructor(props) {
        super();
        this.state={
            name:" ",
            address:" ",
            email:" ",
            designation:" ",
            phone_one:" ",
            phone_two:" ",
            facebook:" ",
            linkedin:" ",
            about:" ",
            loading:true,
            error:false,
            contact_name:"",
            contact_email:"",
            contact_mobile:"",
            contact_msg:""
        }
    }

    componentDidMount() {
      window.scroll(0,0)

      RestClient.GetRequest(ApiUrl.SettingData).then(result=>{
          if(result==null){
              this.setState({ error:true,loading:false})
          }
          else{
              this.setState({
                  name:result[0]['name'],
                  address:result[0]['address'],
                  email:result[0]['email'],
                  phone_one:result[0]['phone_one'],
                  phone_two:result[0]['phone_two'],
                  designation:result[0]['designation'],
                  facebook:result[0]['facebook'],
                  linkedin:result[0]['linkedin'],
                  loading:false
              })
          }


        }).catch(error=>{
            this.setState({ error:true,loading:false})
        })
    }



    nameOnChange=(event)=>{
      let name=  event.target.value;
      this.setState({contact_name:name})
    }

    emailOnChange=(event)=>{
      let email=  event.target.value;
      this.setState({contact_email:email})
    }

    mobileOnChange=(event)=>{
        let mobile=  event.target.value;
        this.setState({contact_mobile:mobile})
    }
    msgOnChange=(event)=>{
        let msg=  event.target.value;
        this.setState({contact_msg:msg})
    }

    onFormSubmit=(event)=>{
        let name=this.state.contact_name;
        let email=this.state.contact_email;
        let mobile=this.state.contact_mobile;
        let msg=this.state.contact_msg;
        let sendBtn=document.getElementById('sendBtn');
        let contactForm=document.getElementById('contactForm');

        if(name.length==0){
            toast.error("Name Required",{
                position:"top-right"
            });
        }

        else if(email.length==0){
            toast.error("E-mail Required",{
                position:"top-right"
            });
        }

        else if(mobile.length==0){
            toast.error("Mobile Number Required",{
                position:"top-right"
            });
        }

        else if(!(validation.EmailRegx).test(email)){
            toast.error("Invalid Email",{
                position:"top-right"
            });
        }

        else if(!(validation.NameRegx).test(name)){
            toast.error("Invalid Name",{
                position:"top-right"
            });
        }

        else if(!(validation.MobileRegx).test(mobile)){
            toast.error("Invalid Mobile Number",{
                position:"top-right"
            });
        }
        else if(msg.length==0){
            toast.error("Please Write Your Message",{
                position:"top-right"
            });
        }
        else{
            sendBtn.innerHTML="Sending...";
            let MyFormData=new FormData();
            MyFormData.append("name",name)
            MyFormData.append("email",email)
            MyFormData.append("mobile",mobile)
            MyFormData.append("message",msg)

            axios.post(ApiUrl.ContactSend,MyFormData).then(function (response) {
                if(response.status==200 && response.data==1){
                    toast.success("Request Success",{
                        position:"top-right"
                    });
                    sendBtn.innerHTML="Send";
                    contactForm.reset();
                }
                else{
                    toast.error("Request Fail ! Try Again",{
                        position:"top-right"
                    });
                    sendBtn.innerHTML="Send"
                }
            }).catch(function (error) {
                toast.error("Request Fail ! Try Again",{
                    position:"top-right"
                });
                sendBtn.innerHTML="Send"
            })
        }


        event.preventDefault();
    }


  render() {
    return (
        <Fragment>
            <div className="contact" id="contact">
                <div className="content-inner">
                    <div className="content-header">
                        <h2>Contact</h2>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className="contact-info">
                                <p><i className="fa fa-user"></i>{ this.state.name }</p>
                                <p><i className="fa fa-tag"></i>{ this.state.designation }</p>
                                <p><i className="fa fa-envelope"></i><a href="mailto:info@example.com">{ this.state.email }</a></p>
                                <p><i className="fa fa-phone"></i><a href="tel:+1234567890">{ this.state.phone_one }</a></p>
                                <p><i className="fa fa-map-marker"></i>{ this.state.address }</p>
                                <div className="social">
                                    <a className="btn" href=""><i className="fab fa-twitter"></i></a>
                                    <a className="btn" href=""><i className="fab fa-facebook-f"></i></a>
                                    <a className="btn" href=""><i className="fab fa-linkedin-in"></i></a>
                                    <a className="btn" href=""><i className="fab fa-instagram"></i></a>
                                    <a className="btn" href=""><i className="fab fa-youtube"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form">
                                <ToastContainer/>
                                <form id="contactForm" onSubmit={this.onFormSubmit}>
                                    <div className="form-row">
                                        <div className="form-group col-md-6">
                                            <input onChange={this.nameOnChange} type="text" className="form-control" placeholder="Your Name" />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <input onChange={this.emailOnChange} type="email" className="form-control" placeholder="Your Email" />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <input onChange={this.mobileOnChange} type="text" className="form-control" placeholder="Your Phone" />
                                    </div>
                                    <div className="form-group">
                                        <textarea onChange={this.msgOnChange} className="form-control" rows="5" placeholder="Message"></textarea>
                                    </div>
                                    <div><button id="sendBtn" className="btn" type="submit">Send Message</button></div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
  }
}

export default Contact