import React, { Component,Fragment } from 'react';
import ReactHtmlParser from "react-html-parser";
import validation from "../../validation/validation";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import Loading from "../Loading/Loading";
import WentWrong from "../WentWrong/WentWrong";
import RestClient from "../../RestApi/RestClient";
import ApiUrl from "../../RestApi/AppUrl";
import axios from 'axios';

class PersonalDetails extends Component {
      constructor(props) {
        super();
        this.state={
            father_name:" ",
            mother_name:" ",
            dob:" ",
            gender:" ",
            marital_status:" ",
            nationality:" ",
            national_id:" ",
            religion:" ",
            permanent_address:" ",
            present_address:"",
            hobby:"",
            loading:true,
            error:false
        }
    }

    componentDidMount() {
      window.scroll(0,0)

      RestClient.GetRequest(ApiUrl.PersonalDetails).then(result=>{
          if(result==null){
              this.setState({ error:true,loading:false})
          }
          else{
              this.setState({
                  father_name:result[0]['father_name'],
                  mother_name:result[0]['mother_name'],
                  dob:result[0]['dob'],
                  gender:result[0]['gender'],
                  marital_status:result[0]['marital_status'],
                  nationality:result[0]['nationality'],
                  national_id:result[0]['national_id'],
                  religion:result[0]['religion'],
                  permanent_address:result[0]['permanent_address'],
                  present_address:result[0]['present_address'],
                  hobby:result[0]['hobby'],
                  loading:false
              })
          }


        }).catch(error=>{
            this.setState({ error:true,loading:false})
        })
    }
  render() {
    return (
      <Fragment>
        <div className="education" id="personal">
            <div className="content-inner">
                <div className="content-header">
                    <h2>Personal Details</h2>
                </div>
                <div className="row align-items-center">
                    <div className="col-md-12">
                        <div className="edu-col">
                            <p><strong>Father's Name:</strong> { this.state.father_name }</p>
                            <p><strong>Mother's Name:</strong> { this.state.mother_name }</p>
                            <p><strong>Date of Birth:</strong> { this.state.dob }</p>
                            <p><strong>Gender:</strong> { this.state.gender }</p>
                            <p><strong>Marital Status:</strong> { this.state.marital_status }</p>
                            <p><strong>Nationality:</strong> { this.state.nationality }</p>
                            <p><strong>National Id no:</strong> { this.state.national_id }</p>
                            <p><strong>Religion:</strong> { this.state.religion }</p>
                            <p><strong>Permanent Address:</strong> { this.state.permanent_address }</p>
                            <p><strong>Current Location:</strong> { this.state.present_address }</p>
                            <p><strong>Language Proficiency:</strong> Bangla & English.</p>
                            <p><strong>Hobby:</strong> { this.state.hobby }</p>

                        </div>
                    </div>
                </div>
            </div>
        </div>
      </Fragment>
    )
  }
}

export default PersonalDetails