import React, { Component,Fragment } from 'react';
import {Link} from "react-router-dom";
import ApiUrl from "../../RestApi/AppUrl";
import ReactHtmlParser from "react-html-parser";
import RestClient from "../../RestApi/RestClient";
import axios from 'axios';
import Loading from "../Loading/Loading";
import WentWrong from "../WentWrong/WentWrong";

class Protfolio extends Component {
    constructor() {
        super();
        this.state={
            myData:[],
            loading:true,
            error:false
        }
    }

    componentDidMount() {
        RestClient.GetRequest(ApiUrl.Projects).then(result=>{
            if(result==null){
                this.setState({ error:true,loading:false})
            }
            else {
                this.setState({myData:result,loading:false})
            }

        }).catch(error=>{
            this.setState({ error:true,loading:false})
        })
    }
  render() {

    if(this.state.loading==true && this.state.error==false ){
        return  <Loading/>
    }
    else if(this.state.loading==false  && this.state.error==false)  {
    const  myList=this.state.myData;
    const myView=myList.map(myList=>{
        return  <div className="col-lg-4 col-md-6 portfolio-item web-des">
                    <div className="portfolio-wrap">
                        <figure>
                            <img src={myList.img} className="img-fluid" alt="" />
                            <a className="portfolio-title" href={"//"+myList.subtitle} target="_blank">Project Name <span>{myList.title}</span></a>
                        </figure>
                    </div>
                </div>
        })

    return (
      <Fragment>
        <div className="portfolio" id="portfolio">
            <div className="content-inner">
                <div className="content-header">
                    <h2>Portfolio</h2>
                </div>

                <div className="row portfolio-container">
{myView}

                </div>
            </div>
        </div>
      </Fragment>
        );
    }
        else if(this.state.error==true){
            return  <WentWrong/>
        }

    }
}

export default Protfolio